import LinkButton from '@/components/commons/link-button';
import Image from 'next/image';
import { memo } from 'react';

function ErrorPage({
  buttonLink,
  buttonText,
  descriptionText,
  image,
  titleText,
}: {
  image: string;
  titleText: string;
  descriptionText: string;
  buttonText: string;
  buttonLink: string;
}) {
  return (
    <div className="grid h-dvh w-dvw items-center justify-items-center gap-6 bg-[#ffeeea] bg-[url('/errors/shapes.svg')] bg-cover bg-no-repeat px-2 lg:px-0">
      <Image src={image} alt="" width={387} height={420} className="self-end" />

      <div className="grid items-center justify-items-center gap-4 self-start text-center">
        <h1 className="font-bold font-lora text-[2.75rem] leading-[3.5rem]">{titleText}</h1>
        <p className="font-semibold text-lg leading-4">{descriptionText}</p>
        <LinkButton href={buttonLink}>{buttonText}</LinkButton>
      </div>
    </div>
  );
}

export default memo(ErrorPage);
