'use client';

import ErrorPage from '@/components/error-page';
import Alien500 from '@/public/errors/alien-500.svg';
import * as Sentry from '@sentry/nextjs';
import type { Metadata } from 'next';
import { useEffect } from 'react';

export const metadata: Metadata = {
  title: 'Erreur technique - Lokappy',
  description:
    "Gérez gratuitement vos locations de la manière la plus simple qu'il soit avec notre logiciel de gestion locative Lokappy.",
};

// biome-ignore lint/suspicious/noExplicitAny: <explanation>
export default function GlobalError({ error }: { error: Error & { digest?: string; props: any } }) {
  useEffect(() => {
    Sentry.captureException(error);
  }, [error]);

  return (
    <html>
      <body>
        <ErrorPage
          image={Alien500}
          titleText="Oups !"
          descriptionText="Notre serveur a été enlevé par des extraterrestres !"
          buttonLink="/"
          buttonText="Revenir à l'accueil"
        />
      </body>
    </html>
  );
}
